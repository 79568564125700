
					@import './src/sass/variables';
					@import './src/sass/mixins';
				






































































































































nav::v-deep {
	display: flex;
	gap: 20px;
	justify-content: center;

	button {
		padding: 0.25rem 0.5rem;
	}
}
